<template>
    <div class="row" style="width:100%; height:100%;"
        :style="{ height: customRowHeight !== '' ? customRowHeight : '100%' }">
        <!-- Table List View Header -->
        <div class="row tl-header" style="width:100%" v-show="controls && controls.length > 0">
            <!-- Title -->
            <div class="title">
                <span> {{ viewTitle }} </span>
            </div>

            <!-- Control Buttons -->
            <!-- <div class="col-8 d-flex align-item-center">
                <button v-for="(control, index) in controls" :key="index" type="button"
                    :class="'btn btn-sm ' + control.class" :style="{ marginRight: '4px' }" :disabled="!isEditMode"
                    @click="handleButtonClick(control)">
                    {{ control.label }}
                </button>
            </div> -->
            <div class="btn-group mr-2">
                <button v-for="(control, index) in controls" :key="index" type="button"
                    :class="'btn btn-sm ' + control.class" :disabled="!isEditMode" @click="handleButtonClick(control)">
                    {{ control.label }}
                </button>
            </div>
        </div>

        <!-- Table List -->
        <div class="row" ref="parentDiv" style="height:calc(100% - 30px); width:100%;">
            <!-- <div ref="parentDiv" class="col pl-0" style="height:100%; width:100%"> -->
            <vue-good-table ref="list-table" style="margin-top: 0.5rem; height:100%; width:100%;" compactMode
                :fixed-header="fixedHeader" :columns="vgtColumns" :rows="vgtRows" :max-height="maxTableHeight"
                :select-options="{ enabled: isEditMode ? true : false, disableSelectInfo: true, selectOnCheckboxOnly: true }"
                :group-options="isGroup ? { enabled: true, collapsable: true } : { enabled: false }"
                :sort-options="{ enabled: isSort}"
                :row-style-class="rowStyleClassFn" @on-row-click="handleRowClick">
                <div slot="emptystate" style="text-align: center;">
                    {{ $t('표시할 데이터가 없습니다.') }}
                </div>

                <template slot="table-row" slot-scope="props">
                    <div v-for="column in columns" :key="column.field">
                        <template v-if="props.column.field === column.field">
                            <!-- Status Toggle Field -->
                            <template v-if="toggleFields.includes(props.column.field)">
                                <b-form-checkbox switch size="md" v-model="props.row[column.field]"
                                    :id="`${props.column.field}-switch-${props.row[keyField]}`"
                                    @change="handleToggle(props.row[keyField], props.column.field, props.row[column.field])"
                                    :disabled="!isEditMode">
                                </b-form-checkbox>
                            </template>
                            <template v-else-if="buttonField.includes(props.column.field)">
                                <button class="btn btn-sm btn-secondary" @click="handleFieldButtonClick(props.row)">{{
                                    buttonText }}</button>
                            </template>
                            <template v-else-if="iconField.includes(props.column.field)">
                                <!-- :disabled="!rows.find(item => item.equipIdx == props.row.equipIdx).ctrlMode" -->
                                <span v-if="rows.find(item => item.equipIdx == props.row.equipIdx).ctrlMode"
                                    class="control-icon" @click="handleFieldButtonClick(props.row)">
                                    <i :class="iconClass" aria-hidden="true"></i>
                                </span>
                                <span v-else class="control-icon"
                                    :style="{ backgroundColor: !rows.find(item => item.equipIdx == props.row.equipIdx).ctrlMode ? '#eab897' : '' }">
                                    <i :class="iconClass" aria-hidden="true"></i>
                                </span>
                            </template>
                            <template v-else-if="propertyField.includes(props.column.field)">
                                <div v-if="rows.find(item => item.propName == props.row.propName).propType == 'Io' &&
                                    rows.find(item => item.propName == props.row.propName).ctrlMode">
                                    <select v-if="rows.find(item => item.propName == props.row.propName).encodeType == 'Enum' &&
                                        rows.find(item => item.propName == props.row.propName).encodeDesc"
                                        class="form-control" :id="props.row.propName"
                                        v-model="rows.find(item => item.propName == props.row.propName).selectProp">
                                        <option :value="null">선택</option>
                                        <option
                                            v-for="([key, item], idx) in Object.entries(JSON.parse(rows.find(item => item.propName == props.row.propName).encodeDesc))"
                                            :value="key"
                                            :key="idx">
                                            {{ item }}
                                        </option>
                                    </select>
                                    <div v-else-if="(rows.find(item => item.propName == props.row.propName).encodeType == 'Range' ||
                                        rows.find(item => item.propName == props.row.propName).encodeType == 'Number') &&
                                        rows.find(item => item.propName == props.row.propName).encodeDesc && 
                                        rows.find(item => item.propName == props.row.propName).ioType !== 'In'">
                                        <b-input-group>
                                            <b-form-input type="range"
                                                v-model="rows.find(item => item.propName == props.row.propName).selectProp"
                                                :min="JSON.parse(rows.find(item => item.propName == props.row.propName).encodeDesc).min"
                                                :max="JSON.parse(rows.find(item => item.propName == props.row.propName).encodeDesc).max"
                                                :step="JSON.parse(rows.find(item => item.propName == props.row.propName).encodeDesc).offset"></b-form-input>
                                            <b-input-group-append class="append-style">
                                                <span>
                                                    {{ rows.find(item => item.propName == props.row.propName).selectProp
                                                        ?
                                                        rows.find(item => item.propName == props.row.propName).selectProp :
                                                        '23' }}
                                                </span>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </div>
                                    <div v-else>
                                        <div
                                            v-if="rows.find(item => item.propName == props.row.propName).ioType !== 'In'">
                                            <b-input-group>
                                                <b-form-input type="range" min="16" max="30" step="0.5"
                                                    v-model="rows.find(item => item.propName == props.row.propName).selectProp"></b-form-input>
                                                <b-input-group-append class="append-style">
                                                    <span>
                                                        {{ rows.find(item => item.propName ==
                                                            props.row.propName).selectProp ?
                                                            rows.find(item => item.propName ==
                                                                props.row.propName).selectProp : '23' }}
                                                    </span>
                                                </b-input-group-append>
                                            </b-input-group>
                                        </div>
                                        <div v-else>
                                            {{ "-" }}
                                        </div>
                                    </div>
                                </div>
                                <div v-else>
                                    {{ "-" }}
                                </div>
                            </template>
                            <!--  -->
                            <template v-else-if="props.column.field == 'ptIdx'">
                                <div @click="showPointDetail(props.row.ptIdx, $event)">
                                    <div v-html="formatValue(props.column.field, props.row)"></div>
                                </div>
                            </template>
                            <!-- 제어 스케줄 페이지 한정 -->
                            <template v-else-if="props.column.field == 'ctrlTarget'">
                                <span v-html="props.row.ctrlTarget"></span>
                            </template>
                            <template v-else-if="props.column.field == 'repeatConfig'">
                                <span>{{ props.row.repeatConfig | koreanDay }}</span>
                            </template>
                            <template v-else-if="props.column.field == 'events'">
                                <span v-html="props.row.events"></span>
                            </template>
                            <template v-else-if="props.column.field == 'validity'">
                                <span v-html="props.row.validity"></span>
                            </template>
                            <template v-else-if="props.column.field == 'lastCtrlDate'">
                                <span v-html="props.row.lastCtrlDate"></span>
                            </template>
                            <!-- Generic Field -->
                            <template v-else>
                                <div>
                                    <div v-html="formatValue(props.column.field, props.row)"></div>
                                </div>
                            </template>
                        </template>
                    </div>
                </template>

            </vue-good-table>
            <!-- </div> -->
            <b-sidebar v-model="isSideBarShow" ref="ptInfoDetail" id="ptInfoDetail" bg-variant="light"
                backdrop-variant="secondary" @hidden="closePointDetail" width="33vw" no-header shadow right
                backdrop>
                <div v-if="isPointDetail" style="height: 100%">
                    <PointInfoDetail ref="point-info-detail" :title="''" :ptIdx="ptIdx" :noWatch="true" @closed="closePointDetail" />
                </div>
            </b-sidebar>
        </div>
    </div>
</template>

<script>
import PointInfoDetail from "@src/views/new/point-management/point-mgmt/PointDetail.vue"

export default {
    props: {
        title: { type: String, default: '' },
        columns: { type: Array, default: () => [] },        // vue-good-table 컬럼 형식
        rows: { type: Array, default: () => [] },           // vue-good-table 로우 데이터
        useFilter: { type: Boolean, default: true },        // Filter Option 사용 여부
        keyField: { type: String, default: '' },            // Key Field Name
        toggleFields: { type: Array, default: () => [] },   // status 필드 리스트
        groupField: { type: String, default: '' },          // Row grouping field name
        transCodes: { type: Array, default: () => [] },     // Translate Codes 
        controls: { type: Array, default: () => [] },       // Table Control Buttons
        mode: { type: String, default: 'view' },             // Edit Mode (view/edit/new)
        useMaxHeight: { type: Boolean, default: true },        // table max-height 사용여부
        customMaxHeight: { type: String, default: "" },
        buttonField: { type: String, default: "" },
        buttonText: { type: String, default: "" },
        iconField: { type: String, default: "" },
        iconClass: { type: String, default: "" },
        customRowHeight: { type: String, default: "" },
        fixedHeader: { type: Boolean, default: true },
        propertyField: { type: String, default: "" },
        isSort: { type: Boolean, default: true }
    },
    components: {
        PointInfoDetail
    },
    // 제어 스케줄 페이지 한정
    filters: {
        koreanDay(repeatConfig) {
            if (!repeatConfig) return '';
            const days = Object.keys(repeatConfig).filter(day => repeatConfig[day]);
            const koreanDays = {
                "Mon": "월",
                "Tue": "화",
                "Wed": "수",
                "Thu": "목",
                "Fri": "금",
                "Sat": "토",
                "Sun": "일"
            };
            return days.map(day => koreanDays[day]).join('/');
        }
    },
    data() {
        return {
            vgtColumns: [],                             // vue-good-table columns 정의
            vgtRows: [],                                // vue-good-table row data
            maxTableHeight: '',                         // vue-good-table 컴포넌트의 크기를 계산하기 위한 부모 요소 참조

            clickedRowIdx: null,                        // Selected Row Index
            propValue: [],
            activeButtons: [],
            // columnField: null,
            isPointDetail: false,
            isSideBarShow: false,
            ptIdx: null,
        }
    },
    async created() {
    },
    mounted() {
        this.setVgtColumns();

        this.setVgtRows();
        this.calculateMaxTableHeight();
        
    },
    watch: {
        // rows 프로퍼티 갱신 감시
        columns() {
            this.setVgtColumns();
        },
        rows() {
            if (!this.rows) this.vgtRows = [];
            else this.setVgtRows();
        },
    },
    computed: {
        isGroup() {
            return (!this.isEmpty(this.groupField));
        },
        isEditMode() {
            return (this.mode !== 'view');
        },
        viewTitle() {
            return this.title;
        },
    },
    methods: {
        closePointDetail() {
            this.$refs.ptInfoDetail.hide();
            this.isSideBarShow = false;
            this.isPointDetail = false;
        },
        async showPointDetail(ptIdx, event) {
            if (ptIdx) {
                this.ptIdx = ptIdx;
                this.isSideBarShow = true;
                this.isPointDetail = true;
                await event.stopPropagation();
            } else return;
        },
        getSelectProperty() {
            return this.rows;
        },
        /** 
         * Event Handler
        */
        handleButtonClick(control) {
            // console.warn('Button Clicked : ',control);
            this.$emit('button-click', control.event, control.options);
        },
        handleToggle(key, field, status) {
            status = (status === true) ? 'Y' : 'N';
            this.$emit('toggle-changed', key, field, status);
        },
        handleFieldButtonClick(data) {
            this.$emit("buttonClick", data);
        },
        handleRowClick(params) {
            const that = this;

            const toggleClick = params.event.target.closest('.custom-control') ? true : false;
            const checkClick = params.event.target.closest('.vgt-checkbox-col') ? true : false;

            // 일반행 처리
            // Change Selected Row
            this.clickedRowIdx = params.row.originalIndex;
            const found = that.rows.find(item => item[that.keyField] === params.row[that.keyField]);
            this.$emit('row-click', found, this.clickedRowIdx, toggleClick, checkClick);
        },
        toggleGroupRow(groupRow) {
            groupRow.__metadata__.expanded = !groupRow.__metadata__.expanded;

            this.$refs['list-table'].refresh();
        },
        getCheckedRows() {
            return this.$refs['list-table'].selectedRows;
        },
        /**
         * vue-good-table Handling
         */
        setVgtColumns() {
            const that = this;

            // 지정된 transCodes를 사용하여 각 column의 filterOptions.filterDropdownItems를 재생성.
            // 그중 Status 필드는 'Y'/'N' 대신 true/false를 사용함.
            that.vgtColumns = [];
            
            that.columns.forEach(async (col) => {
                // 해당 컬럼이 statusFiels에 정의되어 있다면 Transcode
                // col.filterOptions.filterDropdownItems
                let newColumn = col;

                if (that.useFilter) {
                    // filterDropdownItems가 없는 경우 transCodes의 정의된 항목에 대해서 재생성. 
                    // filterOption의 사용자 지정 가능하도록 여지를 둠.
                    if (!newColumn.filterOptions) {
                        const filterOptions = {
                            enabled: true,
                            placeholder: '',
                        };
                        newColumn.filterOptions = filterOptions;
                    }

                    if (newColumn.filterOptions.enabled && !newColumn.filterOptions.filterDropdownItems) {
                        const transCode = that.transCodes.find(code => code.field === newColumn.field)
                        if (transCode) {
                            // transCode가 존재하면
                            if (that.isEmpty(newColumn.filterOptions.placeholder)) newColumn.filterOptions.placeholder = '선택';

                            if (that.toggleFields.includes(newColumn.field)) {
                                // 상태 관리 코드이면 transCode값을 true/false로 변환
                                newColumn.filterOptions.filterDropdownItems
                                    = await transCode.codes.map(item => { return { value: item.value === 'Y' ? true : false, text: item.text } })
                            } else {
                                // 일반 코드이면 transCode를 변환없이 적용                                
                                newColumn.filterOptions.filterDropdownItems = transCode.codes;
                            }
                        } else {
                            if (that.isEmpty(newColumn.filterOptions.placeholder)) newColumn.filterOptions.placeholder = '검색어 입력';
                        }
                    }
                }

                if (!newColumn.hasOwnProperty('thClass')) newColumn.thClass = 'text-center text-nowrap';
                if (!newColumn.hasOwnProperty('tdClass')) newColumn.tdClass = 'text-center text-nowrap';

                that.vgtColumns.push(newColumn);
            })

            // this.vgtColumns = this.columns;
        },
        setVgtRows() { 
            const that = this;      // in Arrow Function Can't Access Vue Instance

            if (!that.rows) return;

            // Grouping 처리전 that.rows로부터 vgtRows에 저장할 임시 row를 생성
            //  - Status Field Checkbox 변환 처리
            //  - Key Field 추가 처리
            let ungroupedRows = [];
            // let resultRows = [];
            that.vgtRows = [];

            // hiddenFields 목록을 추출
            const hiddenFields = that.getHiddenFields();

            that.rows.forEach(row => {
                let newRow = {};

                // Ungrouped Rows                    
                for (let prop in row) {
                    if (that.columns.find(col => col.field === prop)) {
                        //  - Status Field Checkbox 변환 처리
                        if (that.toggleFields.includes(prop)) {                  // Status Field 처리
                            newRow[prop] = (row[prop] === 'Y') ? true : false;
                        } else {                                                 // 일반 Field 처리
                            newRow[prop] = row[prop];
                        }
                    } else if (hiddenFields.includes(prop)) {
                        //  - Hidden Field (keyField 포함) 추가 처리
                        newRow[prop] = row[prop];
                    } else {
                        // console.log('Skipped Saving Property for unused.', prop);                        
                    }
                }
                // Composite Field 처리
                //   - column.compositionFields 를 Join 하여 값을 저장
                //   column에 값이 없는 경우에만 값을 채운다.
                //   column에 값이 있는 경우는 부모 Component에서 명시적으로 값을 전달한 경우로 이를 유지함.
                // compositions : compositionFields를 갖는 column descriptor array
                const compositions = that.columns.filter(col => col.compositionFields);
                if (!that.isEmpty(compositions)) {
                    // composition.field : rowTitle
                    // field : codeName, commonCode, ...
                    compositions.forEach(composition => {
                        if (!row.hasOwnProperty(composition.field)) {
                            let value = composition.compositionFields.map(field => that.transcodeValue(field, row)).join(' ');
                            newRow[composition.field] = value;
                        }
                    });
                }
                const multiColums = that.columns.filter(col => col.multiColums);
                if (!that.isEmpty(multiColums)) {
                    // composition.field : rowTitle
                    // field : codeName, commonCode, ...
                    multiColums.forEach(multiColum => {
                        if (!row.hasOwnProperty(multiColum.field)) {
                            let value = multiColum.multiColums.map(field => that.transcodeValue(field, row)).join(' ');
                            newRow[multiColum.field] = value;
                        }
                    });
                }

                ungroupedRows.push(newRow);
            });

            // console.warn('VGT Rows before grouping ungroupedRows: ', ungroupedRows);

            // tempRows에 Gruoping 이전 상태의 vgtRows 저장            
            // groupField 필드가 설정되어 있다면 지정된 필드를 기준으로 Grouping 처리

            if (!that.isEmpty(that.groupField)) {
                // Update Groupby Column FilterOption
                //  - groupItems : 중복제거된 Group Field의 데이터 값
                let groupItems = that.extractUniqueValues(ungroupedRows, that.groupField);
                that.setGroupFilterOptions(groupItems);

                // Grouped Row 생성                
                //  - children: 해당 Group에 포함된 rows를 저장
                if (groupItems.length > 0) {
                    const groupedRows = groupItems.map(groupValue => {
                        // filterOptions에서 groupValue에 해당하는 text를 찾는다.
                        const column = that.columns.find(col => col.field === that.groupField);
                        let groupText = null;
                        if (column.filterOptions && column.filterOptions.filterDropdownItems) {
                            // dropdownItem.text는 필수가 아님. 단순 String Array일 경우도 감안해서 처리해야 함.
                            const dropdownItem = column.filterOptions.filterDropdownItems.find(item => {
                                return (typeof item === 'string') ? item === groupValue : item.value === groupValue
                            });
                            if (dropdownItem) {
                                groupText = (typeof dropdownItem === 'string') ? dropdownItem : dropdownItem.text;
                            }
                        }

                        // groupText : transCode에서 groupValue에 맵핑되는text를 찾았다면 null이 아닌 문자열이 저장
                        const row = {
                            mode: 'span',
                            label: groupText ?? groupValue,
                            html: false,
                            children: ungroupedRows.filter(row => row[that.groupField] === groupValue),
                        };
                        return row;
                    });
                    // console.warn('Grouped Rows : ', groupedRows);

                    // resultRows.push(...groupedRows);
                    that.vgtRows.push(...groupedRows);
                }
            } else {
                // Grouping을 사용하지 않는 경우.
                // resultRows.push(...ungroupedRows);
                that.vgtRows.push(...ungroupedRows);
            }

            // vgtRows Array의 갱신을 인지시키기 위함.
            // that.vgtRows = [...resultRows];
            that.vgtRows = [...that.vgtRows];

            that.$nextTick(() => {
                this.$refs['list-table'].expandAll();
            })
        },
        setGroupFilterOptions(groupItems) {
            // GroupBy Field는 filterOption이 지정되지 않은 경우에
            // 조회된 데이터를 기준으로 생성한다.
            const that = this;

            if (!this.rows.length) return [];

            const groupColumn = this.columns.find(header => header.field === that.groupField)
            if (groupColumn && groupColumn.filterOptions && !groupColumn.filterOptions.filterDropdownItems) {
                if (that.isEmpty(groupColumn.filterOptions.placeholder)) groupColumn.filterOptions.placeholder = '선택';
                groupColumn.filterOptions.filterDropdownItems = groupItems.map(item => { return { value: item, text: item } });
                console.debug('setGroupFilterOptions. filterdropdownItems:', groupColumn.filterOptions.filterDropdownItems);
            }
        },
        calculateMaxTableHeight() {
            if (this.useMaxHeight) {
                if (this.customMaxHeight) this.maxTableHeight = this.customMaxHeight
                else {
                    if (this.$refs['parentDiv']) {
                        // this.maxTableHeight = Math.floor(this.$refs.parentDiv.clientHeight * 0.987) + 'px';
                        // this.maxTableHeight = Math.floor(this.$refs['parentDiv'].clientHeight * 0.98) + 'px';
                        this.maxTableHeight = "100%";
                    }
                }
            } else {
                this.maxTableHeight = "";
            }
        },
        // vue-good-table 선택 스타일 처리
        rowStyleClassFn(row) {
            if (row.originalIndex === this.clickedRowIdx) {
                return 'selectedRow';
            }
            return 'VGT-row';
        },
        /**
         * Utility Functions         
         */
        transcodeValue(field, row) {
            // Field에 맵핑된 Code가 존재한다면 Code값을 Value Translate함.
            const that = this;

            const transCode = that.transCodes.find(code => code.field == field);
            if (transCode) {
                const code = transCode.codes.find(code => code.value === row[field]);
                if (code) return code.text;
                else return row[field] ?? '-';
            } else {
                return row[field] ?? '-';
            }
        },
        formatValue(field, row) {
            // compositionField와 normalField를 구분하여 표시 문자를 포맷팅함.
            const that = this;

            let formatted = '';

            const compositionColumn = that.columns.find(col => col.field === field && col.compositionFields);
            const multiColumn = that.columns.find(col => col.field === field && col.muntiFields);
            if (compositionColumn) {
                // composition Field Formatting.
                // <p class="mb-1" style="color: #000; font-weight: 800;">{{ props.row.codeName ?? "-" }} </p>
                // <p class="m-0" style="color: #777;">{{ props.row.commonCode ?? "-" }}</p>

                compositionColumn.compositionFields.forEach((subField, index) => {
                    if (!index) {
                        formatted += `<p class="mb-1" style="color: #000; font-weight: 800;">${row[subField] ?? '-'} </p>`;
                    } else {
                        formatted += `<p class="m-0" style="color: #777;">(${row[subField] ?? '-'})</p>`;
                    }
                })
            }
            else if (multiColumn) {
                multiColumn.muntiFields.forEach((subField, index) => {
                    if (!index) {
                        formatted += row[subField] ?? '-'
                    } else {
                        formatted += `(${row[subField] ?? '-'})`
                    }
                })
            }
            else {
                // normal Field
                // if (Array.isArray(row[field])) {
                //     formatted = row[field].length;
                // } else {
                //     formatted = this.transcodeValue(field, row);
                // }
                // if (typeof row[field] === 'number') {
                //     formatted = row[field];
                // } else if (Array.isArray(row[field])) {
                //     formatted = row[field].length;
                // } else {
                //     formatted = this.transcodeValue(field, row);
                // }
                if (Array.isArray(row[field])) {
                    formatted = row[field].length;
                } else {
                    formatted = this.transcodeValue(field, row);
                }
            }

            return formatted;
        },
        // 중복을 제거한 B 속성 값들을 포함한 배열을 반환하는 함수
        extractUniqueValues(arr, prop) {
            if (this.isEmpty(arr)) return [];

            const uniqueValues = new Set(); // 중복을 제거하기 위한 Set 객체 생성

            // 주어진 배열의 각 객체를 순회하면서 B 속성 값만을 추출하여 Set에 추가
            arr.forEach(obj => {
                // 빈 문자열도 허용
                // if (!this.isEmpty(obj[prop])) {
                //     uniqueValues.add(obj[prop]);
                // }
                uniqueValues.add(obj[prop]);
            });

            // Set 객체를 배열로 변환하여 반환 (중복이 제거된 상태)
            return Array.from(uniqueValues);
        },
        // 표시되지는 않으나 row에 포함되어야 할 field 목록 추출
        getHiddenFields() {
            const that = this;

            const uniqueValues = new Set();

            // keyField값은 기본 추가
            uniqueValues.add(that.keyField);

            // columns를 순회하며 compositionFields를 추출하여 Unique한 목록을 반환
            that.columns.forEach(col => {
                if (!that.isEmpty(col.compositionFields)) {
                    col.compositionFields.forEach(field => {
                        uniqueValues.add(field);
                    })
                }
            })

            that.columns.forEach(col => {
                if (!that.isEmpty(col.muntiFields)) {
                    col.muntiFields.forEach(field => {
                        uniqueValues.add(field);
                    })
                }
            })

            return Array.from(uniqueValues);
        },
    },
};
</script>

<style>
.vgt-inner-wrap {
    flex-grow: 1;
    max-height: 98% !important;
    overflow-y: auto !important;
}
</style>

<style scoped>
.row {
    margin-left: 0px;
    margin-right: 0px;
}

.tl-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;

    flex-grow: 0;
    /* transition: flex-grow 0.3s ease; */

    /* background-color: #ececec; */
    background-color: #f1f1f1;

    padding: 7px 0px 7px 16px !important;
    margin-top: 10px;
    margin-bottom: -7px !important;
    font-weight: bold;
    /* display: flex; */

    border-radius: 6px 6px 0px 0px;
    border: solid #ececec 1px;
    border-bottom: 1px solid #ccc;
}

.tl-header .tl-title {
    padding: 0 !important;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.VGT-row:hover {
    background-color: #f2f3f4;
}

.control-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ED7D31;
    transition: background-color 0.5s;
    width: 100%;
    height: 1.8rem;
    border-radius: 5px;
    color: white;
}

.control-icon:hover {
    background-color: #eab897;
}

.btn-group {
    border-radius: 4px;
    border-left: 1px solid #eee;
    border-top: 1px solid #eee;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
}

.btn-group .btn {
    min-width: 30px;
    /* margin: 0px !important;
    border-radius: 4px;
    border-left: 1px solid #eee;
    border-top: 1px solid #eee;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc; */
}

.append-style {
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #999;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    color: white;
}
</style>